import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

import { ScenarioSummaryDTO } from '../api/types/scenario';
import api from '../api';
import { trackEvent } from '../lib/tracking';
import Cookies from 'js-cookie';
import { ProgressIndicator } from './ProgressIndicator';
import { SignUp } from '../pages/SignUp';
import { useEffect, useState } from 'react';
import { isError } from 'lodash';
import { UserDetailsDTO } from '../api/types/auth';
import { ProfileForm } from './ProfileForm';
import { c } from 'vite/dist/node/types.d-aGj9QkWt';

// TODO ... These should really come from the Candidate user profile,
// TODO ... instead of being hardcoded, unless we want the candidate
// TODO ... to behave as if they had other traits. Not sure why.
const DEFAULT_CANDIDATE_PERSONALITY = 'Extrovert';

type StartConversationDialogProps = {
  scenario: ScenarioSummaryDTO;
};

const isProfileComplete = (user: UserDetailsDTO | undefined) => {
  if (!user) return false;

  const required = [
    'birthYear',
    'company',
    'ethnicity',
    'gender',
    'personality',
    'highestEducation',
    'jobTitle',
    'yearsAtCompany',
  ];

  // @ts-ignore: Fix later. Not important.
  return required.every(key => !!user[key]);
}

const StartConversationDialog: React.FC<StartConversationDialogProps> = ({ scenario }) => {
  const [openSignup, setOpenSignup] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [profile, setProfile] = React.useState<UserDetailsDTO>();
  const [loading, setLoading] = React.useState(false);

  const loggedIn = Cookies.get('isAuthenticated') === 'true';
  const navigate = useNavigate();
  const features = useSelector((state: RootState) => state.auth.features);

  const startConversation = async (profile: UserDetailsDTO) => {
    setLoading(true);
    
    const conversation = await api.createConversationFromScenario({
      candidateName: profile.firstName + ' ' + profile.lastName,
      candidatePersonality: profile.personality,
      candidateGender: scenario.candidateGender,
      candidateEthnicity: scenario.candidateEthnicity,
    }, scenario.uuid);

    setLoading(false);
    
    navigate(`/conversation/${conversation.uuid}/`);
  };

  const handleClickOpen = async () => {
    trackEvent('Button', 'Click', 'Start the Conversation');
    
    if (loggedIn) {
      if (!isProfileComplete(profile)) {
        setOpenProfile(true);
      } else {
        if (profile) {
          startConversation(profile);
        }
      }
    } else {
      await api.ping('join');
      setOpenSignup(true);
    }
  };

  const handleProfileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = Object.fromEntries(new FormData(e.currentTarget));

    if (profile) {
      const updatedProfile = {
        ...profile,
        ...form,
      };

      setProfile(updatedProfile);

      if (isProfileComplete(updatedProfile)) {
        try {
          await api.updateMyDetails(updatedProfile);
          setOpenProfile(false);
          startConversation(updatedProfile);
        } catch (err) {
          console.error(err);
        }
      } else {
        // Handle incomplete profile, e.g., show an error message
      }
    }
  };

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const userProfile = await api.getMyDetails();
        setProfile(userProfile);
      } catch (err) {
        console.error(err);
      }
    };

    getUserProfile();
  }, []);

  const handleSignupClose = () => {
    setOpenSignup(false);
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
  };

  // const handleProfileSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   const form = Object.fromEntries(new FormData(e.currentTarget));

  //   if (profile) {
  //     setProfile({
  //       ...profile,
  //       ...form
  //     });
  //   }
  // };

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
    
  //   trackEvent('Button', 'Click', 'Next');
    
  //   if (isFormValid()) {
  //     try {
  //       const acceptance = await api.acceptLicenseAgreement(null, null, formData.email);
       
  //       // this form only shows when user is not logged in
  //       const conversation = await api.createPublicConversationFromScenario({
  //         candidateName: formData.name.trim(),
  //         candidatePersonality: DEFAULT_CANDIDATE_PERSONALITY,
  //         candidateGender: scenario.candidateGender,
  //         candidateEthnicity: scenario.candidateEthnicity,
  //       }, scenario.uuid);

  //       const serializedConversation = {
  //         ...conversation,
  //         createdAt: conversation.createdAt.toISOString(),
  //         responses: conversation.responses?.map(response => ({
  //           ...response,
  //           createdAt: response.createdAt.toISOString(), // Serialize createdAt within responses
  //         })),
  //       };

  //       dispatch(setConversation(serializedConversation));
  //       handleClose();
  //       navigate(`/conversation/${conversation.uuid}/${conversation.correlationUuid}/`);
  //     } catch (error) {
  //       console.error('Error creating public conversation:', error);
  //     }
  //   }
  // };

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const profile = await api.getMyDetails();
        
        setProfile(profile);
      } catch (err) {
        console.error(err);
      }
    };

    getUserProfile();
  }, []);

  return (
    <>
      {loading && (
        <ProgressIndicator projectedTimeMs={28000} intervalMs={500} isComplete={!loading} message='We are finalising the conversation...' />
      )}
      <Dialog
        open={openSignup}
        onClose={handleSignupClose}
        PaperProps={{
          sx: {
            width: '400px',
            height: '720px'
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <DialogContent>
            <SignUp onSuccess={(form) => {
              setOpenSignup(false);

              // @ts-ignore: Fix later.
              startConversation(form);
            }}/>
          </DialogContent>
        </Box>
      </Dialog>
      <Dialog
        open={openProfile}
        onClose={handleProfileClose}
        PaperProps={{
          sx: {
            width: '400px',
            height: '720px'
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <DialogContent>
            <ProfileForm onSubmit={handleProfileSubmit} />
          </DialogContent>
        </Box>
      </Dialog>
      <Box sx={{ m: 2 }}>
        <Button
          sx={{
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
          variant="contained"
          onClick={handleClickOpen}>
          Join Conversation
        </Button>
      </Box>
    </>
  );
};

export default StartConversationDialog;
