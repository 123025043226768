import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Gender, Ethnicity } from "../api/types/common";
import { useState } from "react";
import { j } from "vite/dist/node/types.d-aGj9QkWt";

type ProfileFormProps = {
  submitLabel?: string,
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
};

type ProfileFieldError = {
  name: string;
  error: string;
}

const fields: Record<string, string> = {
  birthYear: 'Birth Year',
  company: 'Company',
  ethnicity: 'Ethnicity',
  gender: 'Gender',
  personality: 'Personality',
  jobTitle: 'Job Title',
  yearsAtCompany: 'Years at Company',
  highestEducation: 'Highest Education',
}

const validate = (form: Record<string, FormDataEntryValue>): ProfileFieldError[] => {
  const required = [
    'birthYear',
    'company',
    'ethnicity',
    'gender',
    'personality',
    'highestEducation',
    'jobTitle',
    'yearsAtCompany',
  ];

  let errors = required.reduce((acc, key) => {
    if (!form[key]) {
      acc.push({
        name: key,
        error: `${fields[key]} is required`
      });
    }
    return acc;
  }, [] as ProfileFieldError[]);

  if (!errors.length) {

  }

  return errors;
};

const getFieldError = (errors: ProfileFieldError[], field: string) => {
  return errors.find(error => error.name === field)?.error;
};

export const ProfileForm = ({
  submitLabel = "Submit my Profile",
  onSubmit
}: ProfileFormProps) => {
  const [errors, setErrors] = useState<ProfileFieldError[]>([]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validate(Object.fromEntries(new FormData(e.currentTarget)));
    if (!errors.length) {
      onSubmit(e);
    } else {
      setErrors(errors);
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h6" sx={{
          mb: '0.5rem'
        }}>
          Complete my Profile
        </Typography>
        <Typography variant="body2">
          Please fill the form below so that we can build a better conversation experience.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="birthYear"
            label="Birth Year"
            name="birthYear"
            type="number"
            helperText={getFieldError(errors, 'birthYear')}
            error={!!getFieldError(errors, 'birthYear')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            required
            id="gender"
            label="gender"
            name="gender"
            defaultValue={Gender.MALE}
          >
            <MenuItem value={Gender.MALE}>Male</MenuItem>
            <MenuItem value={Gender.FEMALE}>Female</MenuItem>
            <MenuItem value={Gender.NON_BINARY}>Non-binary</MenuItem>
            <MenuItem value={Gender.AGENDER}>Agender</MenuItem>
            <MenuItem value={Gender.OTHER}>Other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            required
            id="ethnicity"
            label="ethnicity"
            name="ethnicity"
            defaultValue={Ethnicity.BRITISH}
          >
            <MenuItem value={Ethnicity.INDIAN}>Indian</MenuItem>
            <MenuItem value={Ethnicity.BRITISH}>British</MenuItem>
            <MenuItem value={Ethnicity.CHINESE}>Chinese</MenuItem>
            <MenuItem value={Ethnicity.OTHER_ASIAN}>Other Asian</MenuItem>
            <MenuItem value={Ethnicity.AFRICAN}>African</MenuItem>
            <MenuItem value={Ethnicity.EUROPEAN}>European</MenuItem>
            <MenuItem value={Ethnicity.LATIN_AMERICAN}>Latin American</MenuItem>
            <MenuItem value={Ethnicity.MIDDLE_EASTERN}>Middle Eastern</MenuItem>
            <MenuItem value={Ethnicity.MIXED_ETHNIC}>Mixed Ethnic</MenuItem>
            <MenuItem value={Ethnicity.OTHER_WHITE}>Other White</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="company"
            label="Company"
            name="company"
            type="text"
            helperText={getFieldError(errors, 'company')}
            error={!!getFieldError(errors, 'company')}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            fullWidth
            required
            id="jobTitle"
            label="Job Title"
            name="jobTitle"
            helperText={getFieldError(errors, 'jobTitle')}
            error={!!getFieldError(errors, 'jobTitle')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="yearsAtCompany"
            label="Years at Company"
            name="yearsAtCompany"
            type="number"
            helperText={getFieldError(errors, 'yearsAtCompany')}
            error={!!getFieldError(errors, 'yearsAtCompany')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="highestEducation"
            label="Highest Education"
            name="highestEducation"
            helperText={getFieldError(errors, 'highestEducation')}
            error={!!getFieldError(errors, 'highestEducation')}
          />
        </Grid>
        <Grid item sm={12}>
          <Select
            label="Personality"
            name="personality"
            defaultValue="Extroverted"
            fullWidth
          >
            <MenuItem value="Open to new experience">Open to new experience</MenuItem>
            <MenuItem value="Conscientious">Conscientious</MenuItem>
            <MenuItem value="Extroverted">Extroverted</MenuItem>
            <MenuItem value="Agreeable">Agreeable</MenuItem>
            <MenuItem value="Emotionally reactive">Emotionally reactive</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ height: '48px', mt: '2rem' }}
      >
        {submitLabel}
      </Button>
    </Box>
  );
};
